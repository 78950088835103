import React, { useState, useEffect } from "react"
import ScrollAnimation from 'react-animate-on-scroll';
import { Container, Row, Col, Tabs, Tab, Form, Button, Select } from "react-bootstrap"
import { useStaticQuery, graphql } from "gatsby"
import BookViewing from "../../forms/book-a-viewing"
import PeopleItem from "./PeopleItem"
import "./Connect.scss"
// Header component

const ComEnquiryForm = (props) => {

    const data = useStaticQuery(graphql`
    query enquiryCom {  
        glstrapi{
            globalModule {
                Property_Details_Enquiry_Form {
                    Content
                    Title
                    May_Also_CTA {
                        CTA_Label
                        CTA_Icon {
                            url
                            alternativeText
                        }
                        CTA_Link
                        Icon_ClassName
                    }
                    Dynamics_Form_Details {
                        Department
                        Form_User_ID
                        id
                    }
                }

                Auction_Details {
                    Form_Content
                    Form_Title
                    May_Also_CTA {
                        CTA_Label
                        CTA_Icon {
                            url
                            alternativeText
                        }
                        CTA_Link
                        Icon_ClassName
                    }
        
                }
            }
        }
    }`)

    var global_module = data.glstrapi?.globalModule;

    var department = (props.propertyData?.department === "auction_commercial" || props.propertyData?.department === "auction_residential" || props.department === "auctions") ? "auctions" : props.propertyData?.department;

    var type = (props.propertyData?.department === "residential" || props.propertyData?.department === "commercial") ? "Agency" : "Auctions";
    var sub_type = (props.propertyData?.department === "residential" || props.propertyData?.department === "commercial") ? "Other" : "Other";

    var to_user_id = global_module?.Property_Details_Enquiry_Form?.Dynamics_Form_Details && global_module?.Property_Details_Enquiry_Form?.Dynamics_Form_Details.length > 0 && global_module?.Property_Details_Enquiry_Form?.Dynamics_Form_Details.filter(item => item.Department.toLowerCase() === department.toLowerCase());



    return (
        <React.Fragment>
            <section className="connect-us" id="enquire-now">
                <Container>
                    <Row>
                        <Col>
                            <ScrollAnimation animateIn='fadeInUp' animateOnce={true}>
                                <div className="connect-wrapper">

                                    <div className="contact-form">
                                        <h2>Request a viewing</h2>


                                        <BookViewing propertyData={props.propertyData} department={props.department ? props.department : props.propertyData?.department} location={props.location} type={type} sub_type={sub_type} to_user_id={to_user_id.length > 0 ? to_user_id[0].Form_User_ID : ""} />
                                    </div>
                                    <div className="interest">
                                        {
                                            props?.propertyData?.crm_negotiator_id?.length > 0 &&
                                            <div className="contact-details">
                                                {
                                                    props?.propertyData?.crm_negotiator_id.map((item, index) => {

                                                        if (index < 2) {
                                                            return (
                                                                <PeopleItem key={index} email_id={item?.email} />
                                                            )
                                                        }
                                                    })
                                                }
                                            </div>
                                        }
                                    </div>
                                </div>

                            </ScrollAnimation>
                        </Col>
                    </Row>
                </Container>
            </section>
        </React.Fragment>
    );
};
export default ComEnquiryForm;
