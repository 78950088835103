import React, { useState, useEffect, useRef } from "react"; 
import { Container, Row, Col } from "react-bootstrap";
import ScrollAnimation from "react-animate-on-scroll"; 
import HTMLReactParser from 'html-react-parser'; 
// Header component

const AvailabilityAccommodation = (props) => { 
  return (
    <React.Fragment>
      {
        props?.propertyData &&
        <section className={`availability-accommodation ${props?.propertyData?.department === "auction_commercial" || props?.propertyData?.department === "auction_residential" ? "auction_desc" : ""}`} id="property-data-room">
          <Container>
            <Row>
              <Col>
                <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
                  <div className="property-wrapper">  
                     
                        <div className="accommodation-table-wrap">
                          <h2>Availability/Accommodation </h2>
                          <div class="accommodation-table-container">
                            {HTMLReactParser(props?.propertyData?.extra?.accommodationTable)}
                          </div>
                        </div> 
                  </div>
                </ScrollAnimation>
              </Col>
            </Row>
          </Container>
        </section>
      }
    </React.Fragment>
  );
};
export default AvailabilityAccommodation;
