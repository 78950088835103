import React, { Component, useState, useEffect } from "react";
import { Container, Row, Col, Modal } from "react-bootstrap"; 
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import getVideoId from 'get-video-id';
import HTMLReactParser from 'html-react-parser';
import dateFormat from "dateformat"; 
import ScrollAnimation from "react-animate-on-scroll";
import { People_Email } from "../../../queries/common_use_query" 
import { gtag_report_conversion } from "../../../components/common/pageUtils" 
import PeopleItem from "./CommercialPeopleItem"
import PrevNextProperties from "../../../modules/PrevNextProperties" 
import { getPropAddr } from "../../property-search/utils" 
import PlayVideo from '../../Play/PlayVideo';
import _ from "lodash";

import "./Avenue.scss" 

// Negotiator details
const NegoDetails = (email_id) => {

    const { loading, error, data } = People_Email(email_id);

    // console.log("nego_info ==>", data);
    return data;
}
// Negotiator details

const AsNavFor = (props) => {

    // Property details image count
    const [indexed, setIndex] = useState(1);
    const [isTodayAuction, setIsTodayAuction] = useState(false);

    const afterChangeHandler = (d) => {
        setIndex(d + 1)
    }
    // Property details image count

    let [nav1, slider1] = useState(null)
    let [nav2, slider2] = useState(null);

    const nav_settings = {
        slidesToShow: 7,
        swipeToSlide: true,
        focusOnSelect: true,
        responsive: [
            {
                breakpoint: 1799,
                settings: {
                    slidesToShow: 6,
                },
            },
            {
                breakpoint: 1300,
                settings: {
                    slidesToShow: 4,
                },
            },
        ],
    };

   

    const formattedDate = (currentDate) => {
        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, '0');
        const day = String(currentDate.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }


    useEffect(() => {


        // Future auction date count
        if (props?.propertyData?.department === "auction_residential" || props?.propertyData?.department === "auction_commercial") {

            // if(Date.parse(props?.propertyData?.auctionDate) < new Date().getTime()){
            //     console.log("start is less than end", Date.parse(props?.propertyData?.auctionDate), new Date().getTime())
            //  }else{
            //     console.log("end is less than start", Date.parse(props?.propertyData?.auctionDate), new Date().getTime())
            //  }

            if (props?.propertyData && props?.propertyData?.auctionType === "future" && props?.propertyData?.auctionDate) {

                var futureDays = document.getElementById('futureDays');
                var futureHours = document.getElementById('futureHours');
                var futureMins = document.getElementById('futureMins');
                var futureSeconds = document.getElementById('futureSeconds');


                //today is auction date
                if (formattedDate(new Date()) === formattedDate(new Date(props?.propertyData?.auctionDate))) {
                    setIsTodayAuction(true)
                }
                if (Date.parse(props?.propertyData?.auctionDate) > new Date().getTime()) {
                    var countDownDate = new Date(props?.propertyData?.auctionDate).getTime();
                    var x = setInterval(function () {
                        var currentDate = new Date().getTime();
                        var distance = countDownDate - currentDate;

                        var days = Math.floor(distance / (1000 * 60 * 60 * 24));
                        var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                        var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
                        var seconds = Math.floor((distance % (1000 * 60)) / 1000);

                        if (typeof futureDays !== null && futureDays !== 'undefined') {
                            futureDays.innerHTML = days;
                        }
                        if (typeof futureHours !== null && futureHours !== 'undefined') {
                            futureHours.innerHTML = hours;
                        } if (typeof futureMins !== null && futureMins !== 'undefined') {
                            futureMins.innerHTML = minutes;
                        } if (typeof futureSeconds !== null && futureSeconds !== 'undefined') {
                            futureSeconds.innerHTML = seconds;
                        }
                        // document.getElementById("futureDays").innerHTML = days;
                        // document.getElementById("futureHours").innerHTML = hours;
                        // document.getElementById("futureMins").innerHTML = minutes;
                        // document.getElementById("futureSeconds").innerHTML = seconds;
                    }, 1000);
                } else {

                    if (typeof futureDays !== null && futureDays !== 'undefined') {
                        futureDays.innerHTML = 0;
                    }
                    if (typeof futureHours !== null && futureHours !== 'undefined') {
                        futureHours.innerHTML = 0;
                    } if (typeof futureMins !== null && futureMins !== 'undefined') {
                        futureMins.innerHTML = 0;
                    } if (typeof futureSeconds !== null && futureSeconds !== 'undefined') {
                        futureSeconds.innerHTML = 0;
                    }
                    // document.getElementById("futureDays").innerHTML = 0;
                    // document.getElementById("futureHours").innerHTML = 0;
                    // document.getElementById("futureMins").innerHTML = 0;
                    // document.getElementById("futureSeconds").innerHTML = 0;
                }

            }
        }
        // Future auction date count
    }, [props])

    // Property images lightbox
    const [photoIndex, setPhotoIndex] = useState(0);
    const [isOpen, setIsOpen] = useState(false);

    const openPropertyImage = (e, ind) => {
        e.preventDefault();
        setPhotoIndex(ind);
        setIsOpen(true);
    }
    // Property images lightbox

    // Property details images lightbox
    const propertyImages = props?.propertyData?.images
    var propertyLightImages = propertyImages && propertyImages.map(img => img.srcUrl);
    // Property details images lightbox

    // Video
    const [show, setShow] = useState(false);
    const [fullscreen, setFullscreen] = useState(true);

    const [isPlay, setPlay] = useState(false);
    const [videoId, setVideoId] = useState('');
    const [videoUrl, setVideoUrl] = useState('');

    const [availableLots, setAvailableLots] = useState((sessionStorage.getItem('avaiable_lots') !== null) ? JSON.parse(sessionStorage.getItem('avaiable_lots')) : []);
    function handleShow(breakpoint) {
        setFullscreen(breakpoint);
        setShow(true);
    }
    // Video

    const setVirtualTour = (video_url) => {

        var video_url2 = video_url && getVideoId(video_url);

        if (video_url2?.service === "youtube" || video_url2?.service === "vimeo") {
            setVideoUrl(video_url.replace("https://vimeo.com/manage/videos/", "https://vimeo.com/"));
            setPlay(true);
        } else {
            typeof window !== "undefined" && window.open(video_url);
        }
        // youtube_parser(video_url) ? setVideoId(youtube_parser(video_url)) : setVideoUrl(video_url); 

    }
    const renderTourBtn = (video_url) => {

        var video_url2 = video_url && getVideoId(video_url);

        if (video_url2?.service === "youtube" || video_url2?.service === "vimeo") {
            return true;
        } else {
            return false;
        }


    }

    // Floorplan
    const [floorIndex, setFloorIndex] = useState(0);
    const [isFloorOpen, setIsFloorOpen] = useState(false);

    const floorplanImages = props?.propertyData?.floorplan
    var floorLightbox = floorplanImages && floorplanImages.map(floorImg => floorImg.url);
    if (floorLightbox && floorLightbox.length > 0 && floorLightbox[0] == null) {
        floorLightbox = floorplanImages && floorplanImages.map(floorImg => floorImg.srcUrl);
    }

    const clickFloorplan = (e, ind) => {
        var fileNameFloor = "";
        if (props?.propertyData?.floorplan[0]?.url) {
            fileNameFloor = props?.propertyData?.floorplan[0]?.url;
        } else if (props?.propertyData?.floorplan[0]?.srcUrl) {
            fileNameFloor = props?.propertyData?.floorplan[0]?.srcUrl;
        }
        if (fileNameFloor) {
            var fileextensionFloor = fileNameFloor.split('.').pop();
            if (fileextensionFloor === "pdf") {
                window.open(fileNameFloor);
            } else {
                setIsFloorOpen(true);
            }
        }
    }
    // Floorplan

    //console.log("virtual_tour", props?.propertyData?.virtual_tour);

    // Video id
    // const videoid = props?.propertyData && props?.propertyData?.virtual_tour && props?.propertyData?.virtual_tour.length > 0 && props?.propertyData?.virtual_tour[0].url && getVideoId(props?.propertyData?.virtual_tour[0].url);
    // Video id

    // Negotiator details
    var nego_info = props?.propertyData?.crm_negotiator_id && props?.propertyData?.crm_negotiator_id[0]?.email && NegoDetails(props?.propertyData?.crm_negotiator_id[0]?.email);
    // Negotiator details

    //console.log("auctionType", props?.propertyData?.auctionType);

    var addr = props?.propertyData?.display_address ? getPropAddr(props?.propertyData?.display_address, props?.propertyData?.postcode) : "";

    var virtual_tour = props?.propertyData?.extra?.lotData && props?.propertyData?.extra?.lotData.filter(item => item.Name === "Virtual Viewing Video Link")

    var virtual_tour_comm_res = props?.propertyData && props?.propertyData?.virtual_tour && props?.propertyData?.virtual_tour.length > 0 && props?.propertyData?.virtual_tour[0].url;

    var earlyInvestment = false;
    if (props?.propertyData.auctionId && availableLots.length > 0) {
        if (availableLots[2].future_lot_id.includes(props.auctionId) && props?.propertyData?.status == "NoBids") {
            earlyInvestment = true;
        }
    }

    return (
        <>
            {
                props?.propertyData &&
                <section className="avenue">
                    <Container>
                        <Row>
                            <Col>
                                <div className="avenue-wrapper"> 
                                    <div className={`avenue-info ${props?.propertyData?.department}`}>
                                        <ScrollAnimation animateIn='fadeInLeft' animateOnce={true}>
                                            {
                                                (props?.propertyData?.extra?.lotNumber || props?.propertyData?.extra?.tagline) &&
                                                <address className="lot_number">
                                                    {props?.propertyData?.extra?.lotNumber && "Lot " + props?.propertyData?.extra?.lotNumber + " - "}
                                                    {props?.propertyData?.extra?.tagline && props?.propertyData?.extra?.tagline}
                                                </address>
                                            }

                                            {/* {
                                                props?.propertyData?.display_address &&
                                                <h1>{props?.propertyData?.display_address.replace(props?.propertyData?.postcode,'').toLowerCase()} {props?.propertyData?.postcode}</h1>
                                            } */}
                                            {
                                                addr &&
                                                <h1>{addr}</h1>
                                            }

                                            {
                                                props?.propertyData?.department === "residential" && props?.propertyData?.title &&
                                                <span className="sm-text">
                                                    {props?.propertyData?.title}
                                                </span>
                                            }

                                            {
                                                props?.propertyData?.department === "commercial" && <>
                                                    {props?.propertyData?.introtext &&
                                                        <span className="sm-text">
                                                            {HTMLReactParser(props?.propertyData?.introtext)}
                                                        </span>
                                                    }
                                                </>

                                            }

                                            {
                                                props?.propertyData?.department === "auction_residential" || props?.propertyData?.department === "auction_commercial" ?
                                                    <>
                                                        {
                                                            props?.propertyData?.extra?.resultPrice !== "Withdrawn Prior" &&
                                                            <div className="d-flex align-items-center mb-2">
                                                                <i className="icon-property-price"></i>
                                                                <div className={`mb-0 h3 ${props?.propertyData?.status === "Sold" ? "sld_cls" : ""}`}>
                                                                    {props?.propertyData?.status === "Sold" ?
                                                                        <>{props?.propertyData?.extra?.resultPrice}</>
                                                                        :
                                                                        // <>Guide Price £{props?.propertyData?.price?.toLocaleString()} {props?.propertyData?.max_price && " - £"+props?.propertyData?.max_price?.toLocaleString()}*</>
                                                                        <>Guide Price {props?.propertyData?.extra?.guidePrice} *</>
                                                                    }
                                                                </div>
                                                            </div>
                                                        }

                                                        {
                                                            props?.propertyData?.auctionDate &&
                                                            <>
                                                                <div className="d-flex align-items-center mb-2">
                                                                    <i className="icon-property-calendar"></i>
                                                                    <div className="mb-0 h3">{new Date(props?.propertyData?.auctionDate).toDateString()}</div>
                                                                </div>
                                                                <div className="d-flex align-items-center">
                                                                    <i className="icon-property-clock"></i>
                                                                    <div className="mb-0 h3">{dateFormat(props?.propertyData?.auctionDate, "HH:MMtt")}</div>
                                                                </div>
                                                            </>
                                                        }
                                                    </>
                                                    :
                                                    props?.propertyData?.department === "residential" &&
                                                    (parseFloat(props?.propertyData?.price) > 0 &&
                                                        <div className={`price_txt h3 ${props?.propertyData?.status === "Sold" ? "sld_cls" : ""}`}>
                                                            {props?.propertyData?.status === "Sold" ? "Sold " : ""}
                                                            £{props?.propertyData?.price?.toLocaleString()} {props?.propertyData?.search_type === "lettings" ? "PCM" : ""}
                                                        </div>)
                                            }

                                            {
                                                props?.propertyData?.department === "auction_residential" || props?.propertyData?.department === "auction_commercial" ?
                                                    "" :
                                                    (
                                                        props?.propertyData?.department === "commercial" ?
                                                            <div className="feature-list">
                                                                {
                                                                    props?.propertyData?.price_qualifier === "POA" ?
                                                                        (props?.propertyData?.status &&
                                                                            <div className="features">
                                                                                <i className="icon-property-price-tag"></i>
                                                                                {props?.propertyData?.status} on application
                                                                            </div>)
                                                                        :
                                                                        <div className="features text_sml">
                                                                            <i className="icon-property-price-tag"></i>
                                                                            {props?.propertyData?.price?.toLocaleString()} {props?.propertyData?.max_price && props?.propertyData?.max_price !== props?.propertyData?.price && (" - " + props?.propertyData?.max_price?.toLocaleString())}
                                                                            {props?.propertyData?.extra?.rentPriceMetric ? " per " + props?.propertyData?.extra?.rentPriceMetric.replace('sqft', 'sq ft').replace('sqm', 'sq m') : ""}
                                                                        </div>
                                                                }

                                                                {
                                                                    props?.propertyData?.floorarea_min &&
                                                                    <div className="features text_sml">
                                                                        <i className="icon-sqft"></i>
                                                                        {props?.propertyData?.floorarea_min.toLocaleString()} {props?.propertyData?.floorarea_type.replace('sqft', 'sq ft').replace('sqm', 'sq m')} {props?.propertyData?.floorarea_max && props?.propertyData?.floorarea_max !== props?.propertyData?.floorarea_min && " - " + props?.propertyData?.floorarea_max.toLocaleString() + " " + props?.propertyData?.floorarea_type.replace('sqft', 'sq ft').replace('sqm', 'sq m')}

                                                                    </div>
                                                                }

                                                                {
                                                                    props?.propertyData?.building.length > 0 &&
                                                                    <div className="features">
                                                                        <i className="icon-building-details">
                                                                        </i>
                                                                        {props?.propertyData?.building.join(', ')}
                                                                    </div>
                                                                }
                                                            </div>
                                                            :
                                                            <>
                                                                {
                                                                    props?.propertyData?.bedroom > 0 &&
                                                                    <div className="features">
                                                                        <i className="icon-bed">
                                                                        </i>
                                                                        {props?.propertyData?.bedroom} {props?.propertyData?.bedroom > 1 ? "Bedrooms" : "Bedroom"}
                                                                    </div>
                                                                }

                                                                {
                                                                    props?.propertyData?.bathroom > 0 &&
                                                                    <div className="features">
                                                                        <i className="icon-bath">
                                                                        </i>
                                                                        {props?.propertyData?.bathroom} {props?.propertyData?.bathroom > 1 ? "Bathrooms" : "Bathroom"}
                                                                    </div>
                                                                }

                                                                {
                                                                    props?.propertyData?.building.length > 0 &&
                                                                    <div className="features">
                                                                        <i className="icon-property-house">
                                                                        </i>
                                                                        {props?.propertyData?.building.join(', ')}
                                                                    </div>
                                                                }
                                                            </>
                                                    )
                                            }

                                      

                                            {
                                                props?.propertyData?.department === "auction_residential" || props?.propertyData?.department === "auction_commercial" ?
                                                    <>
                                                        {
                                                            props?.propertyData?.auctionType === "future" &&
                                                            <>
                                                                <div className="mt-5 to_be_auction">
                                                                    <div className="mb-2 .h3">To be auctioned in :</div>
                                                                    <ul className="list-inline m-0">
                                                                        <li className="list-inline-item">
                                                                            <div className="auction-time-card d-flex align-items-center justify-content-center" id="futureDays"></div>
                                                                            <div className="auction-date-text">Days</div>
                                                                        </li>
                                                                        <li className="list-inline-item">
                                                                            <div className="auction-time-card d-flex align-items-center justify-content-center" id="futureHours"></div>
                                                                            <div className="auction-date-text">Hours</div>
                                                                        </li>
                                                                        <li className="list-inline-item">
                                                                            <div className="auction-time-card d-flex align-items-center justify-content-center" id="futureMins"></div>
                                                                            <div className="auction-date-text">Mins</div>
                                                                        </li>
                                                                        <li className="list-inline-item">
                                                                            <div className="auction-time-card d-flex align-items-center justify-content-center" id="futureSeconds"></div>
                                                                            <div className="auction-date-text">Secs</div>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </>
                                                        }
                                                        <ul className="list-inline btn_list m-0">
                                                            {(props?.propertyData?.status?.toLowerCase() !== "sold" && props?.propertyData?.status?.toLowerCase() !== "let") &&
                                                                props?.propertyData?.auctionType === "future" &&
                                                                <>
                                                                    {isTodayAuction &&
                                                                        <li className="list-inline-item">
                                                                            <div className="enquiry d-md-flex">
                                                                                {
                                                                                    props.globalModule?.Register_To_Bid &&
                                                                                    <a href={props.globalModule?.Register_To_Bid} target={"_blank"} className="btn">
                                                                                        Register to Bid
                                                                                    </a>
                                                                                }
                                                                            </div>
                                                                        </li>
                                                                    }

                                                                </>
                                                            }
                                                            {(props?.propertyData?.status?.toLowerCase() !== "sold" && props?.propertyData?.status?.toLowerCase() !== "let") &&
                                                                <li className="list-inline-item">
                                                                    <div className="enquiry d-md-flex">
                                                                        {/* {
                                                                            props.globalModule?.Make_An_Offer &&
                                                                            <a href={props.globalModule?.Make_An_Offer} target={"_blank"} className="btn btn-primary ">
                                                                                make an offer
                                                                            </a>
                                                                        } */}
                                                                        <a href="javascript:void(0)" className="btn enquireNow" onClick={() => gtag_report_conversion()}>
                                                                            make an offer
                                                                        </a>
                                                                    </div>
                                                                </li>
                                                            }

                                                            {/* <PrevNextProperties auctionType={props?.propertyData?.auctionType} department={props?.propertyData?.department} current_id={props?.propertyData?.id}/> */}

                                                            <PrevNextProperties current_id={props?.propertyData?.id} PrevID={props?.propertyData?.extra?.previousLotID} NextID={props?.propertyData?.extra?.nextLotID} department={props?.propertyData?.department} auctionType={props?.propertyData?.auctionType} />
                                                        </ul>
                                                    </>
                                                    :
                                                    (props?.propertyData?.status?.toLowerCase() !== "sold" && props?.propertyData?.status?.toLowerCase() !== "let") && (
                                                        props?.propertyData?.department === "residential" ?
                                                            <div className="enquiry-btn d-none d-md-flex">
                                                                <a href="javascript:void(0)" className="btn enquireNow" onClick={() => gtag_report_conversion()}>
                                                                    make an enquiry
                                                                </a>
                                                            </div>
                                                            :
                                                            <ul className="list-inline btn_list m-0">
                                                                <li className="list-inline-item">
                                                                    <div className="enquiry-btn d-md-flex">
                                                                        <a href="javascript:void(0)" onClick={() => gtag_report_conversion()} className="btn enquireNow">
                                                                            Arrange a viewing
                                                                        </a>
                                                                    </div>
                                                                </li>
                                                                {
                                                                    props?.propertyData?.brochure?.length > 0 && props?.propertyData?.brochure[0]?.srcUrl &&
                                                                    <li className="list-inline-item">
                                                                        <div className="enquiry-btn d-md-flex">
                                                                            <a href={props?.propertyData?.brochure[0]?.srcUrl} target="_blank" className="btn btn-primary">
                                                                            Download brochure
                                                                            </a>
                                                                        </div>
                                                                    </li>
                                                                }

                                                            </ul>
                                                    )
                                            }


                                        </ScrollAnimation>
                                    </div>
                                    <div className="avenue-info">
                                        {
                                            props?.propertyData?.crm_negotiator_id?.length > 0 &&
                                            <div className="contact-details">
                                                {
                                                    props?.propertyData?.crm_negotiator_id.map((item, index) => { 
                                                        if(index < 2){
                                                            return (
                                                                <PeopleItem key={index} email_id={item?.email} />
                                                            )
                                                        }
                                                    })
                                                }
                                            </div>
                                        }
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>

                    {/* Banner image popup */}
                    {isOpen && (
                        <Lightbox
                            mainSrc={propertyLightImages[photoIndex]}
                            nextSrc={propertyLightImages[(photoIndex + 1) % propertyLightImages.length]}
                            prevSrc={propertyLightImages[(photoIndex + propertyLightImages.length - 1) % propertyLightImages.length]}
                            onCloseRequest={() => setIsOpen(false)}
                            onMovePrevRequest={() =>
                                setPhotoIndex((photoIndex + propertyLightImages.length - 1) % propertyLightImages.length)
                            }
                            onMoveNextRequest={() =>
                                setPhotoIndex((photoIndex + 1) % propertyLightImages.length)
                            }
                        />
                    )}
                    {/* Banner image popup */}

                    {/* Floorplan */}
                    {isFloorOpen && floorLightbox && (
                        <Lightbox
                            mainSrc={floorLightbox[floorIndex]}
                            nextSrc={floorLightbox[(floorIndex + 1) % floorLightbox.length]}
                            prevSrc={floorLightbox[(floorIndex + floorLightbox.length - 1) % floorLightbox.length]}
                            onCloseRequest={() => setIsFloorOpen(false)}
                            onMovePrevRequest={() =>
                                setFloorIndex((floorIndex + floorLightbox.length - 1) % floorLightbox.length)
                            }
                            onMoveNextRequest={() =>
                                setFloorIndex((floorIndex + 1) % floorLightbox.length)
                            }
                        />
                    )} 
                    <PlayVideo
                        isOpen={isPlay}
                        isCloseFunction={setPlay}
                        videoId={videoId}
                        videoUrl={videoUrl}
                        isAutoPlay={1}
                    />

                </section>
            }
        </>
    )
}

export default AsNavFor;
