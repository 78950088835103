

import React, { Component, useState, useEffect } from "react";
import { Link } from "gatsby"
import Modal from "react-bootstrap/Modal"
import {People_Email} from "../../../queries/common_use_query"
import {ImageModule} from "../../../modules/Image_Module"
import {CustomLinks} from "../../../components/common/utils"

import MemberForm from "../../../components/forms/member-form"

const PeopleItem = (props) => {
    const [isOpen,setIsOpen] = useState(false);
    const [membDet,setMembDet] = useState('');

    const MemberPopup = (item) =>{
        setMembDet(item);
        setIsOpen(true);
    }

    const { loading, error, data } = People_Email(props.email_id);
    return <>
        {
            data?.peoples?.length > 0 &&
            <div className="contact-person">
                <div className="contact-img">
                    {/* <Link to={CustomLinks.our_people+CustomLinks.find_team+"/"+data?.peoples[0]?.URL+"/"}> */}
                    <picture>
                        <ImageModule ImageSrc={data?.peoples[0]?.Image} altText={data?.peoples[0]?.Name} ggfx_results={data?.peoples[0]?.ggfx_results} imagename="peoples.Image.home_team_avatar" strapi_id={data?.peoples[0]?.id} />
                    </picture>
                    {/* </Link> */}
                </div>
                <div className="contact-info">
                     <div className="h3">
                        {data?.peoples[0]?.Name}
                    </div>
                    <div className="department"> {data?.peoples[0]?.Departments}</div> 
                    <div className="details">  
                        <a href={"javascripti:;"} onClick={() => MemberPopup(data?.peoples[0])} className="mail">{data?.peoples[0]?.Email_ID}</a><br />
                    </div>  
                    <div className="details">
                        <a href={"tel:" + data?.peoples[0]?.Phone_Number} className="tel">
                            {data?.peoples[0]?.Phone_Number} </a>  
                    </div>             
                </div>
            </div>
        }


        <Modal
          id="popup_modal_form"
          show={isOpen}
          onHide={() => setIsOpen(false)}
          backdrop="static"
          keyboard={false}
          className="member-contact-modal"
      > 

        <Modal.Header closeButton className="contact-close-btn memberPopup">
        <Modal.Title className="w-100"><h3 className="text-center content-primary-color mb-0">Contact - {membDet.Name}</h3></Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <MemberForm to_email_id={membDet.Email_ID} to_user_id={membDet.Form_Queue_ID} formName={`Individual Contact - ${membDet.Name}`} formLabel={`Contact ${membDet.Name}`} />
        </Modal.Body>
      </Modal>

    </>
}

export default PeopleItem;